import validator from 'validator';
import moment from 'moment-timezone';
import {CalendarIcon, LogoIcon} from '../../../assets/icons/icons';
import CustomInput from '../../CustomInput/CustomInput';
import SwitchCheckbox from '../../SwitchCheckbox/SwitchCheckbox';
import './AuthForm.css';
import {
  INVALID_DATE_MAX_ERR,
  INVALID_DATE_MIN_ERR,
  INVALID_DATE_VALUE_ERR,
  INVALID_EMAIL_ERR,
  INVALID_PHONE_ERR,
  REQUIRED_ERR,
} from '../../../assets/utils/constants';

function AuthForm({values, setValues, valuesValidity, setValuesValidity}) {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  function handleChange(e) {
    const input = e.target;
    const value = input.type === 'checkbox' ? input.checked : input.value;
    const name = input.name;

    switch (name) {
      case 'email':
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));

        if (!value) {
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: REQUIRED_ERR,
              validState: false,
            },
          }));
        }
        if (value.length >= 3) {
          if (validator.isEmail(value)) {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: '',
                validState: true,
              },
            }));
          } else {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: !input.validity.valid
                  ? input.validationMessage
                  : INVALID_EMAIL_ERR,
                validState: false,
              },
            }));
          }
        }
        break;

      case 'phone':
        let inputValue = value.replace(/\D/g, '');
        let formattedInputValue = '';
        if (!inputValue) {
          setValues((prevValue) => ({
            ...prevValue,
            [name]: '',
          }));
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: REQUIRED_ERR,
              validState: false,
            },
          }));
        } else {
          if (['7', '8', '9'].indexOf(inputValue[0]) > -1) {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: '',
                validState: false,
              },
            }));
            if (inputValue[0] === '9') inputValue = '7' + inputValue;

            let firstSimbols = inputValue[0] === '8' ? '8' : '+7';
            formattedInputValue = firstSimbols + ' ';

            if (inputValue.length > 1) {
              formattedInputValue += inputValue.substring(1, 4);
            }
            if (inputValue.length >= 5) {
              formattedInputValue += ' ' + inputValue.substring(4, 7);
            }
            if (inputValue.length >= 8) {
              formattedInputValue += '-' + inputValue.substring(7, 9);
            }
            if (inputValue.length >= 10) {
              formattedInputValue += '-' + inputValue.substring(9, 11);
            }
            if (inputValue.length >= 11) {
              setValuesValidity((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: '',
                  validState: true,
                },
              }));
            } else {
              setValuesValidity((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: '',
                  validState: false,
                },
              }));
            }
          } else {
            formattedInputValue = '+' + inputValue.substring(0, 16);
            if (inputValue.length >= 11) {
              setValuesValidity((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: '',
                  validState: true,
                },
              }));
            } else {
              setValuesValidity((prevValue) => ({
                ...prevValue,
                [name]: {
                  errorMessage: INVALID_PHONE_ERR,
                  validState: false,
                },
              }));
            }
          }

          setValues((prevValue) => ({
            ...prevValue,
            [name]: formattedInputValue,
          }));
        }
        break;

      case 'date':
        let dateValue = value.replace(/\D/g, '');
        let formattedDateValue = '';

        if (!dateValue) {
          setValues((prevValue) => ({
            ...prevValue,
            [name]: '',
          }));
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: REQUIRED_ERR,
              validState: false,
            },
          }));
        } else {
          setValuesValidity((prevValue) => ({
            ...prevValue,
            [name]: {
              errorMessage: '',
              validState: false,
            },
          }));

          formattedDateValue += dateValue.substring(0, 2);
          if (dateValue.length >= 3) {
            formattedDateValue += '.' + dateValue.substring(2, 4);
          }
          if (dateValue.length >= 5) {
            formattedDateValue += '.' + dateValue.substring(4, 8);
          }
          if (dateValue.length >= 8) {
            const selected_date = moment(formattedDateValue, 'DD.MM.YYYY');
            const date_now = moment();
            const max_date = moment().subtract(18, 'years');
            const min_date = moment('1900-01-01', 'YYYY-MM-DD');

            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: selected_date.isValid()
                ? selected_date.diff(date_now, 'seconds') >= 10
                  ? {
                    errorMessage: INVALID_DATE_VALUE_ERR,
                    validState: false,
                  }
                  : selected_date.diff(max_date, 'seconds') <= 10
                    ? selected_date.diff(min_date, 'seconds') >= 10
                      ? {
                        errorMessage: '',
                        validState: true,
                      } : {
                        errorMessage: INVALID_DATE_MIN_ERR(
                          min_date.format('DD.MM.YYYY')
                        ),
                        validState: false,
                      }
                    : {
                      errorMessage: INVALID_DATE_MAX_ERR(
                        max_date.format('DD.MM.YYYY')
                      ),
                      validState: false,
                    }
                : {
                  errorMessage: INVALID_DATE_VALUE_ERR,
                  validState: false,
                },
            }));
          } else {
            setValuesValidity((prevValue) => ({
              ...prevValue,
              [name]: {
                errorMessage: '',
                validState: false,
              },
            }));
          }

          setValues((prevValue) => ({
            ...prevValue,
            [name]: formattedDateValue,
          }));
        }
        break;

      case 'mobile-date':
        const name_key = input.name_key;
        const selected_date = moment(value);
        const date_now = moment();
        const max_date = moment().subtract(18, 'years');
        const min_date = moment('1900-01-01', 'YYYY-MM-DD');

        setValues((prevValue) => ({
          ...prevValue,
          [name_key]: value,
        }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name_key]: selected_date.isValid()
            ? selected_date.diff(date_now, 'seconds') >= 10
              ? {
                errorMessage: INVALID_DATE_VALUE_ERR,
                validState: false,
              }
              : selected_date.diff(max_date, 'seconds') <= 10
                ? selected_date.diff(min_date, 'seconds') >= 10
                  ? {
                    errorMessage: '',
                    validState: true,
                  } : {
                    errorMessage: INVALID_DATE_MIN_ERR(
                      min_date.format('DD.MM.YYYY')
                    ),
                    validState: false,
                  }
                : {
                  errorMessage: INVALID_DATE_MAX_ERR(
                    max_date.format('DD.MM.YYYY')
                  ),
                  validState: false,
                }
            : {
              errorMessage: INVALID_DATE_VALUE_ERR,
              validState: false,
            },
        }));
        break;

      case 'isMale':
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        break;

      default:
        setValues((prevValue) => ({
          ...prevValue,
          [name]: value,
        }));
        setValuesValidity((prevValue) => ({
          ...prevValue,
          [name]: {
            errorMessage: Boolean(value) ? '' : REQUIRED_ERR,
            validState: Boolean(value),
          },
        }));
        break;
    }
  }

  function handlePhoneDelete(e) {
    if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length === 1) {
      setValues((prevValue) => ({
        ...prevValue,
        phone: '',
      }));
    }
    if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length < 11) {
      setValuesValidity((prevValue) => ({
        ...prevValue,
        phone: {
          errorMessage: '',
          validState: false,
        },
      }));
    }
  }

  return (
    <div className="auth">
      <LogoIcon mainClassName="auth__logo" fillClassName="auth__logo-fill"/>
      <form className="auth__form">
        <CustomInput
          label="Имя"
          value={values.name}
          name="name"
          error={valuesValidity.name.errorMessage}
          onChange={handleChange}
        />
        <CustomInput
          label="Эл. почта"
          value={values.email}
          name="email"
          type="email"
          inputMode="email"
          error={valuesValidity.email.errorMessage}
          onChange={handleChange}
        />
        <CustomInput
          label="Номер телефона"
          value={values.phone}
          name="phone"
          type="tel"
          inputMode="tel"
          error={valuesValidity.phone.errorMessage}
          onChange={handleChange}
          onKeyDown={handlePhoneDelete}
        />
        {isMobile ? (
          <CustomInput
            label="День рождения"
            value={values.date}
            name="date"
            type="date"
            max={moment().format('YYYY-MM-DD')}
            icon={CalendarIcon}
            error={valuesValidity.date.errorMessage}
            onChange={(e) => {
              handleChange({
                target: {
                  value: e.target.value,
                  name: 'mobile-date',
                  name_key: 'date',
                },
              });
            }}
          />
        ) : (
          <CustomInput
            label="День рождения (напр. 31.12.1999)"
            value={values.date}
            name="date"
            inputMode="numeric"
            icon={CalendarIcon}
            error={valuesValidity.date.errorMessage}
            onChange={handleChange}
          />
        )}
        <SwitchCheckbox
          name="isMale"
          checked={values.isMale}
          labels={{trueTitle: 'Мужчина', falseTitle: 'Женщина'}}
          onChange={handleChange}
        />
      </form>
    </div>
  );
}

export default AuthForm;
