import { CheckIcon } from '../../assets/icons/icons';
import './SwitchCheckbox.css';

function SwitchCheckbox({ labels, onChange, ...props }) {
  return (
    <div className="switch">
      <label className="switch__box" htmlFor={props.name}>
        <input
          className="switch__invisible-input"
          id={props.name}
          name={props.name}
          type="checkbox"
          value={props.checked}
          checked={props.checked}
          onChange={onChange}
        />
        <span className="switch__pseudo-item">
          <div
            className={`switch__btn ${
              props.checked ? 'switch__btn_active' : ''
            }`}
          >
            <CheckIcon
              mainClassName="switch__check-icon"
              fillClassName="switch__check-icon-fill"
            />
            <p className="switch__btn-text">{labels.trueTitle}</p>
          </div>
          <div
            className={`switch__btn ${
              !props.checked ? 'switch__btn_active' : ''
            }`}
          >
            <CheckIcon
              mainClassName="switch__check-icon"
              fillClassName="switch__check-icon-fill"
            />
            <p className="switch__btn-text">{labels.falseTitle}</p>
          </div>
        </span>
      </label>
    </div>
  );
}

export default SwitchCheckbox;
