import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sendMetriс } from "../../assets/utils/utils";

function PromoCathcer() {
    const { promo_name } = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        if (!promo_name) {
            sendMetriс('PROMO_UNKNOWN')
            navigate('/')
            return
        }
        switch (promo_name) {
            case 'tg': {
                sendMetriс('PROMO_1')
                break
            }
            case 'promo1': {
                sendMetriс('PROMO_TG')
                break
            }
            case 'site': {
                sendMetriс('PROMO_SITE')
                break
            }

            case 'personal': {
                sendMetriс('PROMO_PERSONAL')
                break
            }

            default: {
                sendMetriс('PROMO_UNKNOWN')
                break;
            }
        }
        navigate('/')
    }, [promo_name])
    return null;
}

export default PromoCathcer;
