export const parseApiError = (error) => {
  if (!error || !error.statusCode) {
    return 'Неизвестная ошибка. Пожалуйста, попробуйте позже.';
  }

  switch (error.statusCode) {
    case 400:
      return 'Данный номер уже зарегистрирован';
    case 401:
      return 'Необходима авторизация. Пожалуйста, войдите в систему.';
    case 403:
      return 'Доступ запрещен. У вас нет прав на выполнение этого действия.';
    case 404:
      return 'Ресурс не найден. Проверьте запрос и попробуйте снова.';
    case 409:
      return 'Конфликт данных. Данные уже существуют или неверны.';
    case 429:
      return 'Слишком много запросов. Пожалуйста, подождите и повторите попытку позже.';
    case 500:
      return 'Внутренняя ошибка сервера. Мы уже работаем над её устранением.';
    case 502:
      return 'Ошибка шлюза. Проблемы с подключением к серверу.';
    case 503:
      return 'Сервис временно недоступен. Попробуйте зайти позже.';
    case 504:
      return 'Время ожидания сервера истекло. Попробуйте повторить запрос позже.';
    default:
      // Generic error messages for different ranges of status codes
      if (error.statusCode >= 400 && error.statusCode < 500) {
        return 'Произошла ошибка на стороне клиента. Пожалуйста, проверьте ваш запрос.';
      }
      if (error.statusCode >= 500) {
        return 'Произошла ошибка на стороне сервера. Мы уже работаем над её устранением.';
      }
      return 'Неизвестная ошибка. Пожалуйста, попробуйте позже.';
  }
};


export const sendMetriс = (value) => {
  window.ym(98083466, 'reachGoal', value)
  }
  