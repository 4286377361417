import { TextLogoIcon } from '../../../assets/icons/icons';
import card from '../../../assets/images/card-bg.webp';
import apple from '../../../assets/images/apple-wallet-btn.png';
import google from '../../../assets/images/google-wallet-btn.png';
import './SuccessMsg.css';
import { sendMetriс } from '../../../assets/utils/utils';

function SuccessMsg({ cardData }) {
  return (
    <div className="success">
      <p className="success__title">ПОЗДРАВЛЯЕМ!</p>
      <p className="success__subtitle">
        Ваша карта выпущена
        <br />и готова к использованию
      </p>

      <div className="success__card">
        <img className="success__card-img" src={card} alt="" />
        <TextLogoIcon
          mainClassName="success__card-logo"
          fillClassName="success__card-logo-fill"
        />
      </div>

      <ul className="success__wallets">
        <li className="success__item">
          <a
            className="success__wallet-btn"
            href={cardData?.cards?.apple_wallet?.file?.url}
            target="_blank"
            rel="noreferrer"
            onClick={()=>{
              sendMetriс('APPLE_WALLET')
            }}
          >
            {/*<AppleWalletButton*/}
            {/*    mainClassName="success__wallet-icon"*/}
            {/*/>*/}
            <img className="success__wallet-icon" src={apple} alt="" />
            {/*<p className="success__wallet-text">Добавить в Apple Wallet</p>*/}
          </a>
        </li>

        <li className="success__item">
          <a className="success__wallet-btn" href={`intent://import/${cardData?.cards?.apple_wallet?.file?.url}#Intent;scheme=walletpasses;package=io.walletpasses.android;S.browser_fallback_url=https://play.google.com/store/apps/details?id=io.walletpasses.android&hl=ru&referrer=${encodeURI(cardData?.cards?.apple_wallet?.file?.url)};end`}   onClick={()=>{
              sendMetriс('GOOGLE_WALLET')
            }}>
            <img className="success__wallet-icon" src={google} alt="" />
          </a>
        </li>
      </ul >
    </div >
  );
}

export default SuccessMsg;
