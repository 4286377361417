import './Footer.css';

function Footer({ step }) {
  return (
    <footer className="footer">
      <p
        className={`footer__text ${
          step === 0 ? 'footer__text_color_contrast' : ''
        }`}
      >
        © ООО «Вайн Виски» – 2024
      </p>
    </footer>
  );
}

export default Footer;
