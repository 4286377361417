import { Link } from 'react-router-dom';
import './NotFoundPage.css';

function NotFoundPage() {
  return (
    <div className='not-found-page'>
      <p className='not-found-page__title'>404</p>
      <Link className='not-found-page__link' to={'/'}>На главную</Link>
    </div>
  );
}

export default NotFoundPage;
