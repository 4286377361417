import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { motion, AnimatePresence } from 'framer-motion';
import './Main.css';
import {
  BottomTriangleBg,
  LogoIcon,
  TopTriangleBg,
} from '../../assets/icons/icons';
import AuthForm from './AuthForm/AuthForm';
import SuccessMsg from './SuccessMsg/SuccessMsg';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import mainApi from '../../assets/api/MainApi';
import { parseApiError } from '../../assets/utils/utils';
import useAutoDismissError from '../../assets/hooks/useAutoDismissError';

import privacy_policy from '../../assets/docs/privacy-policy.pdf'

function Main({ step, setStep }) {
  const [error, showError] = useAutoDismissError();
  const [isPreloader, setIsPreloader] = useState(false);
  const [cardData, setCardData] = useState(
    localStorage.getItem('card_data') !== null
      ? JSON.parse(localStorage.getItem('card_data'))
      : undefined
  );
  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    date: '',
    isMale: true,
  });
  const [valuesValidity, setValuesValidity] = useState({
    name: {},
    email: {},
    phone: {},
    date: {},
  });
  const isFormValid =
    valuesValidity.name.validState &&
    valuesValidity.email.validState &&
    valuesValidity.phone.validState &&
    valuesValidity.date.validState;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  function handleNextStep() {
    setStep((prevVal) => prevVal + 1);
  }

  function handleSubmitForm() {
    setIsPreloader(true);
    mainApi
      .signup({
        email: values.email,
        full_name: values.name,
        phone: values.phone.replace(/\D/g, ''),
        data_of_birth: moment(values.date, 'YYYY-MM-DDTHH:mm:ssZ').format(
          'YYYY-MM-DDTHH:mm:ssZ'
        ),
        gender: values.isMale,
      })
      .then((res) => {
        setStep((prevVal) => prevVal + 1);
        localStorage.setItem('card_data', JSON.stringify(res));
        setCardData(res);
      })
      .catch((err) => {
        switch (err.statusCode) {
          case 400:
            setValuesValidity((prevValue) => ({
              ...prevValue,
              phone: {
                errorMessage: parseApiError(err),
                validState: false,
              },
            }));
            break;

          default:
            showError(parseApiError(err));
            break;
        }
      })
      .finally(() => {
        setIsPreloader(false);
      });
  }

  return (
    <main className={`main ${step === 0 ? 'main_type_start' : ''}`}>
      <div
        className={`main__content ${
          step === 0 ? 'main__content_type_start' : ''
        }`}
      >
        <AnimatePresence initial={false} mode="popLayout">
          {step === 0 && (
            <motion.div
              className="main__container"
              key={step}
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className="main__start-box">
                <LogoIcon
                  mainClassName="main__start-logo"
                  fillClassName="main__start-logo-fill"
                />
                <h1 className="main__start-text">
                  Электронная скидочная карта в&nbsp;твоем&nbsp;телефоне!
                </h1>
                <div className="main__start-bg-shadow" />
              </div>
            </motion.div>
          )}

          {step === 1 && (
            <motion.div
              className="main__container"
              key={step}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <AuthForm
                {...{ values, setValues, valuesValidity, setValuesValidity }}
              />
            </motion.div>
          )}

          {step === 2 && (
            <motion.div
              className="main__container"
              key={step}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <SuccessMsg {...{ cardData }} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className="main__footer">
        {step === 0 && (
          <p className="main__agreement">
            Нажимая кнопку «Получить», вы принимаете
            <br />
            <a className="main__agreement-link" href={privacy_policy} target="_blank" rel='noreferrer'>
              условия договора обработки персональных данных
            </a>
          </p>
        )}

        <AnimatePresence initial={false}>
          {step !== 2 && (
            <motion.button
              className={`main__btn ${
                (step === 1 && !isFormValid) || isPreloader
                  ? 'main__btn_disabled'
                  : ''
              }`}
              onClick={step === 0 ? handleNextStep : handleSubmitForm}
              key="button"
              initial={{ opacity: 1, backgroundColor: 'var(--active-btn-bg)' }}
              animate={{
                opacity: 1,
                backgroundColor:
                  step === 1 && !isFormValid
                    ? 'var(--inactive-btn-bg)'
                    : 'var(--active-btn-bg)',
              }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <AnimatePresence initial={false} mode="popLayout">
                {isPreloader ? (
                  <motion.div
                    className="main__preloader"
                    key="preloader"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <MiniPreloader />
                  </motion.div>
                ) : (
                  <motion.p
                    className="main__btn-text"
                    key={step}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    {step === 0 ? 'Получить' : 'Продолжить'}
                  </motion.p>
                )}
              </AnimatePresence>
            </motion.button>
          )}
        </AnimatePresence>

        <AnimatePresence initial={false}>
          {Boolean(error) && (
            <motion.div
              className="main__error"
              initial={{
                height: '0px',
                marginTop: '0px',
                opacity: 0,
                visibility: 'hidden',
              }}
              animate={{
                height: 'fit-content',
                marginTop: '6px',
                opacity: 1,
                visibility: 'visible',
              }}
              exit={{
                height: '0px',
                marginTop: '0px',
                opacity: 0,
                visibility: 'hidden',
              }}
              transition={{ duration: 0.2 }}
            >
              <p className="main__error-msg">{error}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <AnimatePresence initial={false} mode="popLayout">
        {step === 1 && (
          <motion.div
            className="main__top-bg"
            key="top"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <TopTriangleBg
              mainClassName="main__triangle"
              fillClassName="main__triangle-fill"
            />
          </motion.div>
        )}

        {step !== 0 && (
          <motion.div
            className="main__bottom-bg"
            key="bottom"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <BottomTriangleBg
              mainClassName="main__triangle"
              fillClassName="main__triangle-fill"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </main>
  );
}

export default Main;
