import { useState } from 'react';
import './App.css';
import Main from '../Main/Main';
import Footer from '../Footer/Footer';
import useWindowSize from '../../assets/hooks/useWindowSize';
import { Route, Routes } from 'react-router-dom';
import PromoCathcer from '../PromoCathcer/PromoCathcer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

function App() {
  const [step, setStep] = useState(
    localStorage.getItem('card_data') !== null ? 2 : 0
  );
  const { height } = useWindowSize();

  return (
    <div
      className={`app ${step === 0 ? 'app__color_contrast' : ''}`}
      style={{ minHeight: height }}
    >
      <Routes>
        <Route index element={
          <>
            <Main {...{ step, setStep }} />
            <Footer {...{ step }} />
          </>
        }>
        </Route>
        <Route path='/r/:promo_name' element={
          <PromoCathcer />
        }>
        </Route>
        <Route path='*' element={
          <NotFoundPage />
        }>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
